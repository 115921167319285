import React from "react";
import StyledTimetable from "./styled";

const Timetable = () => {
  return (
    <StyledTimetable className="timetable animate">
      <h2>Ordinační hodiny</h2>
      <table className="timetable__week">
        <tbody>
          <tr>
            <th>Pondělí</th>
            <td rowSpan="5">
              9:00 - 11:30
              <br />
              15:00 - 17:00
            </td>
          </tr>
          <tr>
            <th className="lead">Až</th>
          </tr>
          <tr>
            <th>Čtvrtek</th>
          </tr>
        </tbody>
      </table>
      <table className="timetable__week">
        <tbody>
          <tr>
            <th>Pátek</th>
            <td>9:00 - 11:30</td>
          </tr>
        </tbody>
      </table>
      <table className="timetable__week">
        <tbody>
          <tr>
            <th>Sobota</th>
            <td rowSpan="2">Zavřeno</td>
          </tr>
          <tr>
            <th>Neděle</th>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          boxShadow: "var(--shadow-color)",
          padding: "1.8rem 1.8rem 1.8rem 0.8rem",
          borderRadius: "10px",
          margin: "2.8rem 0 0 0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <svg
          style={{ fill: "var(--primary)", width: "100px" }}
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="19" r="2"></circle>
          <path d="M10 3h4v12h-4z"></path>
        </svg>
        <p>
          Každé ošetření vašeho zvířete je nutné{" "}
          <a href="#contact">
            <strong>objednat telefonicky</strong>
          </a>{" "}
          nebo <strong>osobně</strong>.
        </p>
      </div>
    </StyledTimetable>
  );
};

export default Timetable;
