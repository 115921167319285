import React from 'react';
import Navigation from '../../components/Navigation';

const Header = () => {

    return(
        <header>
            <Navigation />
        </header>
    )
}

export default Header;